<template>

    <div>
        <rxNavBar class ="aa" title="优惠券" androidOrIOSFlag="true"></rxNavBar>
        <!-- 主体的列表们 -->
        <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
            <div class="bo-dy" >
                <div class="lists" v-for=" (item,i) in couponList " :key="i" @click="discountAction({row:item})">
                    <img class="liwu" src="../../../assets/images/liwu.png">
                    <img class="coupon" src="../../../assets/images/coupon.png">
                    <div class="title">{{item.couponName}}</div>
                    <!-- <div class="useplace">使用门店：大连海米之家</div> -->
                    <div class="time" v-if="item.couponStatus == 0 && (item.couponBeginTime && item.couponEndTime)">有效期：{{item.couponBeginTime}} 至 {{item.couponEndTime}} </div>
                    <div class="time" v-if="item.couponStatus == 1">已使用 </div>
                    <div class="time" v-if="item.couponStatus == 2">已过期 </div>
                    <div class="smalltitle">{{item.couponType}}</div>
                    <div class="money" v-if="item.couponType=='抵值券'">¥{{item.couponMoney}}</div>
                    <div class="money" v-if="item.couponType=='折扣券'">{{Number(item.discount*10).toFixed(2)}}折</div>
                </div>
            </div>
        </van-list>
        <!--空状态显示-->
        <common-empty v-if="isEmptuyFlag"></common-empty>
    </div>

</template>

<script>
    import {
        NavBar,
        Picker,
        List,
        PullRefresh,
        Toast,
        Popup,
    } from "vant";
    Vue.use(Picker);
    Vue.use(List);
    Vue.use(PullRefresh);
    Vue.use(Popup);
    Vue.use(PullRefresh);
    import {
        queryBaseData,
        userCouponList
    } from "../../../getData/getData";
    import {
        checkAndroid, checkIOS,
        getUserId,
        globaluserId,
        responseUtil
    } from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/

    //****************************原生选择优惠券方法 start ***********************

    function selectCoupon(userCouponId) {
        window.himi.setUserCoupon(userCouponId)
    }
    //****************************原生选择优惠券方法 end   ***********************

    export default {
        name: "myOrder",
        data() {
            return {
                fromType:'',    //原生选择优惠券标记
                columns: [{
                    dictionaryTitle: "全部",
                    dictionaryValue: "0"
                }],
                show: false,
                showPicker: false,
                condition: true, //用来控制两种状态的切换条件
                isOccupationShow: false,
                loading: false,
                finished: false,
                currentPage: '1', //当前页
                pageCount: '', //总页数
                numberPage: '10', //每页条数
                count: 0,
                certificateType: '',
                defaultIndex: '',
                swi: true,
                isLoading: false,
                totalPage: '',
                couponStatus: 0, //传入时的优惠券状态(0 未使用 1 已使用 2 已过期)
                couponList: [], //优惠券列表
                isEmptuyFlag: false
            }
        },
        components: {
            [NavBar.name]: NavBar,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            dialMobile,
            rxNavBar

        },
        created() {
            this.initData();
            // 原生选择优惠券传参type 1
            this.fromType = this.$route.query.type
            this.couponTypeIdList = this.$route.query.couponTypeId
            // this.initDicData();
        },
        //初始化
        mounted() {},
        methods: {
            //初始化数据
            initData() {
                const that = this
                let initData = {
                    user_id: globaluserId()
                }
            },
            //优惠券接口
            userCouponList() {
                const that = this
                if (that.dictionaryValue == 0) {
                    that.dictionaryValue = ""
                }
                let initData = {}
                initData.user_id = globaluserId() //userid
                initData.couponTypeIdList = that.couponTypeIdList //优惠券类型id
                // initData.couponTypeIdList = '1,2' //优惠券类型id
                initData.couponStatus = '0' // 优惠券状态
                initData.isPastDueFlag = '1' // 是否去掉过期优惠券
                initData.currentPage = that.currentPage //当前页
                initData.numberPage = that.numberPage //每页条数
                userCouponList(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if (response.data.code == 0) {
                            console.log(response.data.data)
                            that.pageCount = response.data.data.pageCount //总页数
                            if (response.data.data.pageCount == 0) {
                                that.isEmptuyFlag = true
                            }else{
                                that.isEmptuyFlag = false
                            }
                            var totalPage = response.data.data.totalPage //总条数
                            var list = response.data.data.userCouponList
                            if (totalPage == 0 || that.pageCount == 0 || !list) { //没有数据时
                                that.loading = false
                                that.finished = true
                            }
                            if (list && that.pageCount != 0) { //有数据时
                                var currentPage = parseInt(that.currentPage); //当前页
                                var pages = that.pageCount; //总页数
                                if (pages >= currentPage) { //当前页不大于总页数
                                    that.currentPage = currentPage + 1
                                    for (let i = 0; i < list.length; i++) {
                                        that.couponList.push(list[i])
                                    }
                                    that.loading = false
                                    // console.log("长度=" + that.userLeaseChangeListZ.length)
                                } else {
                                    that.loading = false
                                    that.finished = true
                                }
                            }
                        } else if (response.data.code != 0) {
                            // responseUtil.alertMsg(that, response.data.msg)
                        } else {
                            // responseUtil.alertMsg(that, response.data.msg)
                        }
                        if (that.couponList.length == 0) {
                            that.swi = false
                        } else {
                            that.swi = true
                        }


                    })
                })
            },
            //未使用
            showOnOrder() {
                var that = this
                that.condition = true
                that.couponStatus = 0
                that.couponList = []
                this.default();
                this.onLoad();
            },
            //已使用
            showFinished() {
                var that = this
                that.condition = false
                that.couponStatus = 1
                that.couponList = []
                this.default();
                this.onLoad();
            },
            //返回上一页
            leftReturn() {
                this.$router.go(-1)
            },
            //分页
            onLoad() {
                this.userCouponList();
            },
            default () { //默认值
                this.pageCount = ''
                this.currentPage = '1'
            },
            discountAction(e) {
                let userCouponId = e.row.userCouponId
                let couponTypeId = e.row.couponUseTypeId
                let couponMoney = e.row.couponMoney
                let couponEndTime = e.row.couponEndTime
                let billFeeTypeId = e.row.billFeeTypeId  //费用类型id

                var date = new Date();
                var seperator1 = "-";
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
                if(couponEndTime < currentdate){
                    Toast('当前优惠券已过期')
                }else{
                    if (openInWebview()) {
                        if(checkAndroid()){
                            selectCoupon(userCouponId);
                        }else if(checkIOS()){
                            window.webkit.messageHandlers.selectCoupon.postMessage({body: userCouponId});
                        }
                    }

                }

                //未使用且未过期的优惠券，选择后调用原生方法
                selectCoupon(userCouponId,couponMoney,couponTypeId,billFeeTypeId);
                // console.log(couponEndTime + "优惠券截止日期")
                // console.log(currentdate + "当前日期")

                // if (couponType == '抵值券') {
                // 	console.log(couponMoney)
                // }
                // if (couponType == '折扣券') {
                // 	console.log(discount)
                // }
            },
        },

    }
</script>

<style scoped>
    .empty {
        margin-top: 250px;
        text-align: center;
    }

    .empty img {
        width: 85px;
    }

    .empryText {
        text-align: center;
        color: #ACACAC;
        color: rgba(172, 172, 172, 1);
        font-size: 12px;
        margin-top: 50px;
    }

    .bo-dy {
        margin-top: 30px;
    }

    .lists {
        position: relative;
        background: url(../../../assets/images/bak.png) no-repeat;
        background-size: cover;
        height: 80px;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(228,228,228);
    }

    .liwu {
        position: absolute;
        bottom: 0px;
        right: 10px;
        width: 45px;
        height: 45px;
    }

    .coupon {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 15px;
        height: 15px;
    }

    .title,
    .title2 {
        font-size: 15px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        float: left;
        margin-left: 45px;
        margin-top: 16px;
        width: 210px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .title2 {
        color: #999999;
    }

    .useplace {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
        position: absolute;
        left: 20px;
        top: 45px;
    }

    .time {
        color: rgba(153, 153, 153, 1);
        font-size: 10px;
        font-family: PingFangSC-Regular;
        position: absolute;
        bottom: 12px;
        left: 20px;
    }

    .smalltitle,
    .smalltitle2 {
        color: rgba(34, 34, 34, 1);
        font-size: 13px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        position: absolute;
        top: 30px;
        right: 25px;
    }

    .smalltitle2 {
        color: #999999;
    }

    .money,
    .money2 {
        color: rgba(255, 93, 59, 1);
        font-size: 15px;
        font-family: Oswald-Regular;
        font-weight: 600;
        position: absolute;
        right: 28px;
        top: 50px;
    }

    .money2 {
        color: #999999;
    }
</style>
